import { createRouter, createWebHashHistory } from "vue-router";
import App from "./App.vue";

const routes = [
  {
    path: "/",
    name: "app",
    component: App,
    children: [
      {
        path: "",
        name: "login",
        component: () => import("./components/LoginPage.vue"),
      },
      {
        path: "/landing",
        name: "landing",
        component: () => import("./components/LandingPage.vue"),
      },
      {
        path: "/edit_or_new",
        name: "edit_or_new",
        component: () => import("./components/EditOrNewPage.vue"),
      },
      {
        path: "/register",
        name: "register",
        component: () => import("./components/RegisterPage.vue"),
      },
      {
        path: "/set_password",
        name: "set_password",
        component: () => import("./components/SetPasswordPage.vue"),
      },
      {
        path: "/view",
        name: "view",
        component: () => import("./components/ViewPage.vue"),
      },
      {
        path: "/status",
        name: "status",
        component: () => import("./components/StatusPage.vue"),
      },
      {
        path: "/tutorial",
        name: "tutorial",
        component: () => import("./components/TutorialPage.vue"),
      },
      {
        path: "/query_branch",
        name: "query_branch",
        component: () => import("./components/QueryBranchPage.vue"),
      },
      {
        path: "/portal",
        name: "portal",
        component: () => import("./components/PortalPage.vue"),
      },
      {
        path: "/rv",
        name: "register_verification",
        component: () => import("./components/RegisterVerificationPage.vue"),
      },
      {
        path: "/register_success",
        name: "register_success",
        component: () => import("./components/RegisterSuccessPage.vue"),
      },
    ],
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/notfound",
    name: "notfound",
    component: () => import("./pages/NotFound.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
